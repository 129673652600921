//CSS
import "./css/FormNavigation.css";

//ASSETS

//COMPONENTS


const FormNavigation = (props) => {

    return(
        <div className="form-navigation">
            {
                props.pages.map((page, i) => {
                    let itemClass = "form-navigation-item";
                    if (typeof page.extraclass !== "undefined") itemClass += " "+page.extraclass;
                    if (page.completed) itemClass += " active";
                    
                    return(
                        <div className={itemClass} key={i+1}>
                            <div className="index">{i+1}</div>
                            <div className="title">{page.title}</div>
                        </div> 
                    ); 
                })
            }
        </div>
    );
}

export default FormNavigation;