//CSS
import "./css/FormPage.css";

//ASSETS

//COMPONENTS
import React from 'react';

const FormPage = (props) => {
    let pageClass = "form-page";
    if (!props.visible) pageClass += " hidden";
    
    return(
        <div className={pageClass}>
            {
                (props.note)
                ?
                <div className="note" dangerouslySetInnerHTML={{__html:props.note}}></div>
                :
                <React.Fragment></React.Fragment>
            }
            
            {props.children}
            
            <div className="buttons-wrapper">
                { 
                    props.buttons.map((button) => { 
                        
                        let buttonClasses = "button";
                        if (button.type === "submit"){
                            buttonClasses += " submit";
                        }
                        return(
                           (typeof button.info !== "undefined")
                            ?
                                <div className="button-container" key={button.label}>
                                    <div className="button-info" dangerouslySetInnerHTML={{__html:button.info}}></div>
                                    <div className={buttonClasses} onClick={button.onClick}>
                                        {button.label}
                                    </div>
                                </div>
                            :
                                <div className={buttonClasses} key={button.label} onClick={button.onClick}>
                                    {button.label}
                                </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default FormPage;