//CSS
import "./css/Logo.css";

//ASSETS
import logo from "../assets/svgs/HKJS_MainLogo.svg";

//COMPONENTS
import ContentWrapper from "./layoutcomponents/ContentWrapper";
import Title from "./layoutcomponents/Title";

const Logo = (props) => {

    return(
        <div className="logo">
            <ContentWrapper>
                <div className="logo-wrapper">
                    <img src={logo} alt="HKJ Logo"/>
                </div>
                <Title size="2" tag="h2">Payment Processing</Title>
            </ContentWrapper>
        </div>
    );
}

export default Logo;