//CSS
import "./css/FormBlockDescription.css";

//ASSETS

//COMPONENTS


const FormBlockDescription = (props) => {

    return(
        <div className="form-block-description">
            {props.children}
        </div>
    );
}

export default FormBlockDescription;