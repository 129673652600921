//CSS
import "./css/FormBlockFields.css";

//ASSETS

//COMPONENTS


const FormBlockFields = (props) => {

    return(
        <div className="form-block-fields">
            {props.children}
        </div>
    );
}

export default FormBlockFields;