//CSS
import "./css/FormField.css";

//ASSETS

//COMPONENTS
import React, {useState} from 'react';


const FormField = (props) => {
    const [helpVisible, setHelpVisible] = useState(false);
    
    let fieldClass = "form-field";
    
    const iconHelpClickedHandler = () => {
        setHelpVisible( (prevState) => {
            return !prevState;
        })
    };
    
    const isNumeric = (str) => {
      if (typeof str != "string") return false
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
             !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    };
    
    //Define Input Field JSX Content
    let inputField;
    let valueIsValid = true;
    let errorMsg = props.error;
    
    switch (props.type){
        case "checkbox":
            //*** CHECKBOX
            fieldClass += " checkbox";
            inputField =
                <React.Fragment>
                    <div className="form-field-checkbox">
                        <input type="checkbox" id={props.id} name={props.id} onChange={props.onChange} defaultChecked={props.value}/>
                        <div className="txt-error">{props.error}</div>
                        <div className="txt-notes" dangerouslySetInnerHTML={{__html: props.notes}}></div>
                    </div>
                    <label className="checkboxLabel" htmlFor={props.id}>{props.children}</label>
                </React.Fragment>
            break;
        case "radio":
            //*** RADIO
            fieldClass += " radio";
            inputField =
                <React.Fragment>
                    <div className="form-field-radio">
                        <input type="radio" id={props.id} name={props.id} onChange={props.onChange} defaultChecked={props.value}/>
                        <div className="txt-error">{props.error}</div>
                        <div className="txt-notes" dangerouslySetInnerHTML={{__html: props.notes}}></div>
                    </div>
                    <label className="radioLabel" htmlFor={props.id}>{props.children}</label>
                </React.Fragment>
            break;
        case "select":
            //*** SELECT DROPDOWN
            fieldClass += " select";
            valueIsValid = props.value!=="";
            inputField =
                <React.Fragment>
                    <label htmlFor={props.id}>{props.children}</label>
                    <div className="form-field-select">
                        <select id={props.id} name={props.id} onChange={props.onChange} required defaultValue={props.value}>
                            {
                                props.options.map(option => {
                                if (option.value===""){
                                    return (
                                        <option value={option.value} key={option.value} disabled>{option.label}</option>
                                    );
                                }else {
                                    return (
                                        <option value={option.value} key={option.value}>{option.label}</option>
                                    );
                                }
                                })
                            }
                        </select>
                        <div className="txt-error">{props.error}</div>
                        <div className="txt-notes" dangerouslySetInnerHTML={{__html: props.notes}}></div>
                    </div>
                </React.Fragment>
            break;
        case "email":
            //*** EMAIL INPUT TEXT
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (props.value===""){
                valueIsValid = false;
            }
            else{
                valueIsValid = emailRegex.test(props.value);
                errorMsg = "Please enter a valid email address";
            }
            inputField =
                <React.Fragment>
                    <label htmlFor={props.id}>{props.children}{(typeof props.help !== "undefined") && <span className="icon-help" onClick={iconHelpClickedHandler}>?</span>}</label>
                    { 
                        (typeof props.help !== "undefined")
                        ?
                        <div className={(helpVisible) ? "box-help visible" : "box-help"} onClick={iconHelpClickedHandler}>{props.help}</div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    <div className="form-field-input">
                        <input type="text" id={props.id} name={props.id} onChange={props.onChange} value={props.value}/>
                        <div className="txt-error">{errorMsg}</div>
                        <div className="txt-notes" dangerouslySetInnerHTML={{__html: props.notes}}></div>
                    </div>
                </React.Fragment>
            break;
        case "amount":
            //*** AMOUNT INPUT TEXT
            if (props.value===""){
                valueIsValid = false;
            }
            else{
                valueIsValid = isNumeric(props.value);
                errorMsg = "The amount must be a number";
            }
            inputField =
                <React.Fragment>
                    <label htmlFor={props.id}>{props.children}{(typeof props.help !== "undefined") && <span className="icon-help" onClick={iconHelpClickedHandler}>?</span>}</label>
                    { 
                        (typeof props.help !== "undefined")
                        ?
                        <div className={(helpVisible) ? "box-help visible" : "box-help"} onClick={iconHelpClickedHandler}>{props.help}</div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    <div className="form-field-input">
                        <input type="text" id={props.id} name={props.id} onChange={props.onChange} value={props.value}/>
                        <div className="txt-error">{errorMsg}</div>
                        <div className="txt-notes" dangerouslySetInnerHTML={{__html: props.notes}}></div>
                    </div>
                </React.Fragment>
            break;
        default:
            //*** INPUT TEXT
            valueIsValid = props.value!=="";
            inputField =
                <React.Fragment>
                    <label htmlFor={props.id}>{props.children}{(typeof props.help !== "undefined") && <span className="icon-help" onClick={iconHelpClickedHandler}>?</span>}</label>
                    { 
                        (typeof props.help !== "undefined")
                        ?
                        <div className={(helpVisible) ? "box-help visible" : "box-help"} onClick={iconHelpClickedHandler}>{props.help}</div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    <div className="form-field-input">
                        <input type="text" id={props.id} name={props.id} onChange={props.onChange} value={props.value}/>
                        <div className="txt-error">{props.error}</div>
                        <div className="txt-notes" dangerouslySetInnerHTML={{__html: props.notes}}></div>
                    </div>
                </React.Fragment>
            break;
    }
    
    if (!valueIsValid && props.currentPageRequestedValidation) fieldClass += " error";
    
    return(
        <div className={fieldClass}>
            {inputField}
        </div>
    );
}

export default FormField;