//CSS
import "./css/ContentWrapper.css";


const ContentWrapper = (props) => {
    let classes = "content-wrapper";
    if (typeof props.className !== "undefined"){
        classes += props.className;
    }
    
    return(
        <div className={classes}>{props.children}</div>
    );
}

export default ContentWrapper;