//CSS
import "./css/Results.css";

//ASSETS

//COMPONENTS
import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import ContentWrapper from "./layoutcomponents/ContentWrapper";
import FormNavigation from "./formcomponents/FormNavigation";
import Title from "./layoutcomponents/Title";

const urlDecode = (url) => {
    // Split the URL-encoded string into key-value pairs
    const keyValuePairs = url.split('&');

    // Create an empty object to store the result
    const result = {};

    // Iterate over the key-value pairs
    keyValuePairs.forEach(keyValue => {
        const [key, value] = keyValue.split('=');
        // URL-decode the key and value
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        // Assign the key and value to the object
        result[decodedKey] = decodedValue;
    });

    return result;
};


const Results = (props) => {
    const [failStatusMsg, setFailStatusMsg] = useState();
    
    //const sageDecryptRequestURL = "http://localhost/paymentssageform/public/php/sagedecryptrequest.php";
    //const sageDecryptRequestURL = "https://dev.plus-two.com/paymentssageform/php/sagedecryptrequest.php"; //P2 Dev server
    //const sageDecryptRequestURL = "https://havenkj-payments.test.astonlarkhosting.com/php/sagedecryptrequest.php"; //Concourse Testing
    const sageDecryptRequestURL = "https://payments.havenkjspecialist.com/php/sageencryptrequest.php"; //Concourse Live
    
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const crypt = query.get('crypt');

    
    useEffect(() => {
        if (!props.success && crypt !== null){
            //in case of FAIL decrypt the result to show errors
            let xmlhttp = new XMLHttpRequest();
            xmlhttp.open("POST", sageDecryptRequestURL, true);
            
            xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xmlhttp.onreadystatechange = function() {
                if (xmlhttp.readyState === XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                    if (xmlhttp.status === 200) {
                        let sagePayResponse = urlDecode(xmlhttp.responseText);
                        //console.log(urlDecode(xmlhttp.responseText));
                        setFailStatusMsg(sagePayResponse.StatusDetail);
                    }
                    else if (xmlhttp.status === 400) {
                        alert('There was an error 400');
                    }
                    else {
                        alert('Something else other than 200 was returned');
                    }
                }
            };
            xmlhttp.send("crypt="+crypt.substring(1)); 
        }
    }, []);
    
    
    let result =
        <ContentWrapper>
            <FormNavigation
                pages={[
                    {id:"details", title:"Payment Details", completed:true},
                    {id:"confirm", title:"Confirm Payment", completed:true},
                    {id:"complete", title:"Payment Complete", completed:true},
                ]}
            />
            <div className="box-alert success">
                <Title size="1" tag="h2">Payment Succesful</Title>
                <p>Thank you.<br/>Your payment has been successful.<br/>You will receive email confirmation shortly.</p>
                <a className="link" href="https://www.havenkjspecialist.com">Return to Main Site</a>
            </div>
        </ContentWrapper>
    
    if (!props.success){
        result = 
            <ContentWrapper>
                <FormNavigation
                    pages={[
                        {id:"details", title:"Payment Details", completed:true},
                        {id:"confirm", title:"Confirm Payment", completed:true},
                        {id:"failed", title:"Payment Failed", completed:true, extraclass:"fail"},
                    ]}
                />
                <div className="box-alert fail">
                    <Title size="1" tag="h2">Payment Failed</Title>
                    <p>Your payment has not been completed.</p>
                    <Title size="4" tag="h3">ERROR:</Title>
                    <p><em>{failStatusMsg}</em></p>
                    <Link className="link" to="/">Start Over</Link>
                </div>
            </ContentWrapper>
    }
    
    return(
        <React.Fragment>
        {result}
        </React.Fragment>
    );
}

export default Results;