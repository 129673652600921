//CSS
import "./css/FormBlock.css";

//ASSETS

//COMPONENTS


const FormBlock = (props) => {

    return(
        <div className="form-block">
            {props.children}
        </div>
    );
}

export default FormBlock;