//CSS
import "./css/Form.css";

//ASSETS
import exampleRef from "../assets/images/HKJ-Specialist_Example.jpg";

//COMPONENTS
import React, {useState, useEffect} from 'react';

import ContentWrapper from "./layoutcomponents/ContentWrapper";
import Title from "./layoutcomponents/Title";
import FormNavigation from "./formcomponents/FormNavigation";
import FormPage from "./formcomponents/FormPage";
import FormBlock from "./formcomponents/FormBlock";
import FormBlockDescription from "./formcomponents/FormBlockDescription";
import FormBlockFields from "./formcomponents/FormBlockFields";
import FormFieldsGroup from "./formcomponents/FormFieldsGroup";
import FormField from "./formcomponents/FormField";

const Form = (props) => {
    //*** OPTIONS
    //const sageEncryptRequestURL = "http://localhost/paymentssageform/public/php/sageencryptrequest.php";
    //const sageEncryptRequestURL = "https://dev.plus-two.com/paymentssageform/php/sageencryptrequest.php"; //P2 Dev server
    //const sageEncryptRequestURL = "https://havenkj-payments.test.astonlarkhosting.com/php/sageencryptrequest.php"; //Concourse Testing
    const sageEncryptRequestURL = "https://payments.havenkjspecialist.com/php/sageencryptrequest.php"; //Concourse Live
    
    //*** PAGES STATES
    const [currentPageId, setCurrentPageId] = useState("details");
    const [currentPageRequestedValidation, setCurrentPageRequestedValidation] = useState(false);
    const [encryptedOutput, setEncryptedOutput] = useState("");
    
    //*** All FIELDS STATES
    //Policy Holder Details
    const [deliveryFirstnames, setDeliveryFirstnames] = useState("");
    const [deliverySurname, setDeliverySurname] = useState("");
    const [customerEMail, setCustomerEMail] = useState("");
    const [deliveryPhone, setDeliveryPhone] = useState("");
    const [deliveryAddress1, setDeliveryAddress1] = useState("");
    const [deliveryCity, setDeliveryCity] = useState("");
    const [deliveryPostCode, setDeliveryPostCode] = useState("");
    const [deliveryCountry, setDeliveryCountry] = useState("");
    const [deliveryState, setDeliveryState] = useState("");
    //Billing Details
    const [sameBillingAddress, setSameBillingAddress] = useState(true);
    const [billingFirstnames, setBillingFirstnames] = useState("");
    const [billingSurname, setBillingSurname] = useState("");
    const [billingPhone, setBillingPhone] = useState("");
    const [billingAddress1, setBillingAddress1] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingPostCode, setBillingPostCode] = useState("");
    const [billingCountry, setBillingCountry] = useState("");
    const [billingState, setBillingState] = useState("");
    //Payment Details
    const [referrerID, setReferrerID] = useState("");
    const [amount, setAmount] = useState("");
    
    
    const countriesList = [
        {value:"", label:"Please Select..."},
        {value:"GB", label:"United Kingdom"},
        {value:"AF" , label:"Afghanistan"},
        {value:"AX" , label:"Åland Islands"},
        {value:"AL" , label:"Albania"},
        {value:"DZ" , label:"Algeria"},
        {value:"AS" , label:"American Samoa"},
        {value:"AD" , label:"Andorra"},
        {value:"AO" , label:"Angola"},
        {value:"AI" , label:"Anguilla"},
        {value:"AQ" , label:"Antarctica"},
        {value:"AG" , label:"Antigua and Barbuda"},
        {value:"AR" , label:"Argentina"},
        {value:"AM" , label:"Armenia"},
        {value:"AW" , label:"Aruba"},
        {value:"AU" , label:"Australia"},
        {value:"AT" , label:"Austria"},
        {value:"AZ" , label:"Azerbaijan"},
        {value:"BS" , label:"Bahamas"},
        {value:"BH" , label:"Bahrain"},
        {value:"BD" , label:"Bangladesh"},
        {value:"BB" , label:"Barbados"},
        {value:"BY" , label:"Belarus"},
        {value:"BE" , label:"Belgium"},
        {value:"BZ" , label:"Belize"},
        {value:"BJ" , label:"Benin"},
        {value:"BM" , label:"Bermuda"},
        {value:"BT" , label:"Bhutan"},
        {value:"BO" , label:"Bolivia (Plurinational State of)"},
        {value:"BQ" , label:"Bonaire, Sint Eustatius and Saba"},
        {value:"BA" , label:"Bosnia and Herzegovina"},
        {value:"BW" , label:"Botswana"},
        {value:"BV" , label:"Bouvet Island"},
        {value:"BR" , label:"Brazil"},
        {value:"IO" , label:"British Indian Ocean Territory"},
        {value:"BN" , label:"Brunei Darussalam"},
        {value:"BG" , label:"Bulgaria"},
        {value:"BF" , label:"Burkina Faso"},
        {value:"BI" , label:"Burundi"},
        {value:"CV" , label:"Cabo Verde"},
        {value:"KH" , label:"Cambodia"},
        {value:"CM" , label:"Cameroon"},
        {value:"CA" , label:"Canada"},
        {value:"KY" , label:"Cayman Islands"},
        {value:"CF" , label:"Central African Republic"},
        {value:"TD" , label:"Chad"},
        {value:"CL" , label:"Chile"},
        {value:"CN" , label:"China"},
        {value:"CX" , label:"Christmas Island"},
        {value:"CC" , label:"Cocos (Keeling) Islands"},
        {value:"CO" , label:"Colombia"},
        {value:"KM" , label:"Comoros"},
        {value:"CG" , label:"Congo"},
        {value:"CD" , label:"Congo, Democratic Republic of the"},
        {value:"CK" , label:"Cook Islands"},
        {value:"CR" , label:"Costa Rica"},
        {value:"CI" , label:"Côte d'Ivoire"},
        {value:"HR" , label:"Croatia"},
        {value:"CU" , label:"Cuba"},
        {value:"CW" , label:"Curaçao"},
        {value:"CY" , label:"Cyprus"},
        {value:"CZ" , label:"Czechia"},
        {value:"DK" , label:"Denmark"},
        {value:"DJ" , label:"Djibouti"},
        {value:"DM" , label:"Dominica"},
        {value:"DO" , label:"Dominican Republic"},
        {value:"EC" , label:"Ecuador"},
        {value:"EG" , label:"Egypt"},
        {value:"SV" , label:"El Salvador"},
        {value:"GQ" , label:"Equatorial Guinea"},
        {value:"ER" , label:"Eritrea"},
        {value:"EE" , label:"Estonia"},
        {value:"SZ" , label:"Eswatini"},
        {value:"ET" , label:"Ethiopia"},
        {value:"FK" , label:"Falkland Islands (Malvinas)"},
        {value:"FO" , label:"Faroe Islands"},
        {value:"FJ" , label:"Fiji"},
        {value:"FI" , label:"Finland"},
        {value:"FR" , label:"France"},
        {value:"GF" , label:"French Guiana"},
        {value:"PF" , label:"French Polynesia"},
        {value:"TF" , label:"French Southern Territories"},
        {value:"GA" , label:"Gabon"},
        {value:"GM" , label:"Gambia"},
        {value:"GE" , label:"Georgia"},
        {value:"DE" , label:"Germany"},
        {value:"GH" , label:"Ghana"},
        {value:"GI" , label:"Gibraltar"},
        {value:"GR" , label:"Greece"},
        {value:"GL" , label:"Greenland"},
        {value:"GD" , label:"Grenada"},
        {value:"GP" , label:"Guadeloupe"},
        {value:"GU" , label:"Guam"},
        {value:"GT" , label:"Guatemala"},
        {value:"GG" , label:"Guernsey"},
        {value:"GN" , label:"Guinea"},
        {value:"GW" , label:"Guinea-Bissau"},
        {value:"GY" , label:"Guyana"},
        {value:"HT" , label:"Haiti"},
        {value:"HM" , label:"Heard Island and McDonald Islands"},
        {value:"VA" , label:"Holy See"},
        {value:"HN" , label:"Honduras"},
        {value:"HK" , label:"Hong Kong"},
        {value:"HU" , label:"Hungary"},
        {value:"IS" , label:"Iceland"},
        {value:"IN" , label:"India"},
        {value:"ID" , label:"Indonesia"},
        {value:"IR" , label:"Iran (Islamic Republic of)"},
        {value:"IQ" , label:"Iraq"},
        {value:"IE" , label:"Ireland"},
        {value:"IM" , label:"Isle of Man"},
        {value:"IL" , label:"Israel"},
        {value:"IT" , label:"Italy"},
        {value:"JM" , label:"Jamaica"},
        {value:"JP" , label:"Japan"},
        {value:"JE" , label:"Jersey"},
        {value:"JO" , label:"Jordan"},
        {value:"KZ" , label:"Kazakhstan"},
        {value:"KE" , label:"Kenya"},
        {value:"KI" , label:"Kiribati"},
        {value:"KP" , label:"Korea (Democratic People's Republic of)"},
        {value:"KR" , label:"Korea, Republic of"},
        {value:"KW" , label:"Kuwait"},
        {value:"KG" , label:"Kyrgyzstan"},
        {value:"LA" , label:"Lao People's Democratic Republic"},
        {value:"LV" , label:"Latvia"},
        {value:"LB" , label:"Lebanon"},
        {value:"LS" , label:"Lesotho"},
        {value:"LR" , label:"Liberia"},
        {value:"LY" , label:"Libya"},
        {value:"LI" , label:"Liechtenstein"},
        {value:"LT" , label:"Lithuania"},
        {value:"LU" , label:"Luxembourg"},
        {value:"MO" , label:"Macao"},
        {value:"MG" , label:"Madagascar"},
        {value:"MW" , label:"Malawi"},
        {value:"MY" , label:"Malaysia"},
        {value:"MV" , label:"Maldives"},
        {value:"ML" , label:"Mali"},
        {value:"MT" , label:"Malta"},
        {value:"MH" , label:"Marshall Islands"},
        {value:"MQ" , label:"Martinique"},
        {value:"MR" , label:"Mauritania"},
        {value:"MU" , label:"Mauritius"},
        {value:"YT" , label:"Mayotte"},
        {value:"MX" , label:"Mexico"},
        {value:"FM" , label:"Micronesia (Federated States of)"},
        {value:"MD" , label:"Moldova, Republic of"},
        {value:"MC" , label:"Monaco"},
        {value:"MN" , label:"Mongolia"},
        {value:"ME" , label:"Montenegro"},
        {value:"MS" , label:"Montserrat"},
        {value:"MA" , label:"Morocco"},
        {value:"MZ" , label:"Mozambique"},
        {value:"MM" , label:"Myanmar"},
        {value:"NA" , label:"Namibia"},
        {value:"NR" , label:"Nauru"},
        {value:"NP" , label:"Nepal"},
        {value:"NL" , label:"Netherlands"},
        {value:"NC" , label:"New Caledonia"},
        {value:"NZ" , label:"New Zealand"},
        {value:"NI" , label:"Nicaragua"},
        {value:"NE" , label:"Niger"},
        {value:"NG" , label:"Nigeria"},
        {value:"NU" , label:"Niue"},
        {value:"NF" , label:"Norfolk Island"},
        {value:"MK" , label:"North Macedonia"},
        {value:"MP" , label:"Northern Mariana Islands"},
        {value:"NO" , label:"Norway"},
        {value:"OM" , label:"Oman"},
        {value:"PK" , label:"Pakistan"},
        {value:"PW" , label:"Palau"},
        {value:"PS" , label:"Palestine, State of"},
        {value:"PA" , label:"Panama"},
        {value:"PG" , label:"Papua New Guinea"},
        {value:"PY" , label:"Paraguay"},
        {value:"PE" , label:"Peru"},
        {value:"PH" , label:"Philippines"},
        {value:"PN" , label:"Pitcairn"},
        {value:"PL" , label:"Poland"},
        {value:"PT" , label:"Portugal"},
        {value:"PR" , label:"Puerto Rico"},
        {value:"QA" , label:"Qatar"},
        {value:"RE" , label:"Réunion"},
        {value:"RO" , label:"Romania"},
        {value:"RU" , label:"Russian Federation"},
        {value:"RW" , label:"Rwanda"},
        {value:"BL" , label:"Saint Barthélemy"},
        {value:"SH" , label:"Saint Helena, Ascension and Tristan da Cunha"},
        {value:"KN" , label:"Saint Kitts and Nevis"},
        {value:"LC" , label:"Saint Lucia"},
        {value:"MF" , label:"Saint Martin (French part)"},
        {value:"PM" , label:"Saint Pierre and Miquelon"},
        {value:"VC" , label:"Saint Vincent and the Grenadines"},
        {value:"WS" , label:"Samoa"},
        {value:"SM" , label:"San Marino"},
        {value:"ST" , label:"Sao Tome and Principe"},
        {value:"SA" , label:"Saudi Arabia"},
        {value:"SN" , label:"Senegal"},
        {value:"RS" , label:"Serbia"},
        {value:"SC" , label:"Seychelles"},
        {value:"SL" , label:"Sierra Leone"},
        {value:"SG" , label:"Singapore"},
        {value:"SX" , label:"Sint Maarten (Dutch part)"},
        {value:"SK" , label:"Slovakia"},
        {value:"SI" , label:"Slovenia"},
        {value:"SB" , label:"Solomon Islands"},
        {value:"SO" , label:"Somalia"},
        {value:"ZA" , label:"South Africa"},
        {value:"GS" , label:"South Georgia and the South Sandwich Islands"},
        {value:"SS" , label:"South Sudan"},
        {value:"ES" , label:"Spain"},
        {value:"LK" , label:"Sri Lanka"},
        {value:"SD" , label:"Sudan"},
        {value:"SR" , label:"Suriname"},
        {value:"SJ" , label:"Svalbard and Jan Mayen"},
        {value:"SE" , label:"Sweden"},
        {value:"CH" , label:"Switzerland"},
        {value:"SY" , label:"Syrian Arab Republic"},
        {value:"TW" , label:"Taiwan, Province of China"},
        {value:"TJ" , label:"Tajikistan"},
        {value:"TZ" , label:"Tanzania, United Republic of"},
        {value:"TH" , label:"Thailand"},
        {value:"TL" , label:"Timor-Leste"},
        {value:"TG" , label:"Togo"},
        {value:"TK" , label:"Tokelau"},
        {value:"TO" , label:"Tonga"},
        {value:"TT" , label:"Trinidad and Tobago"},
        {value:"TN" , label:"Tunisia"},
        {value:"TR" , label:"Turkey"},
        {value:"TM" , label:"Turkmenistan"},
        {value:"TC" , label:"Turks and Caicos Islands"},
        {value:"TV" , label:"Tuvalu"},
        {value:"UG" , label:"Uganda"},
        {value:"UA" , label:"Ukraine"},
        {value:"AE" , label:"United Arab Emirates"},
        {value:"US" , label:"United States of America"},
        {value:"UM" , label:"United States Minor Outlying Islands"},
        {value:"UY" , label:"Uruguay"},
        {value:"UZ" , label:"Uzbekistan"},
        {value:"VU" , label:"Vanuatu"},
        {value:"VE" , label:"Venezuela (Bolivarian Republic of)"},
        {value:"VN" , label:"Viet Nam"},
        {value:"VG" , label:"Virgin Islands (British)"},
        {value:"VI" , label:"Virgin Islands (U.S.)"},
        {value:"WF" , label:"Wallis and Futuna"},
        {value:"EH" , label:"Western Sahara"},
        {value:"YE" , label:"Yemen"},
        {value:"ZM" , label:"Zambia"},
        {value:"ZW" , label:"Zimbabwe"}
    ];
    
    const USStatesList = [
        {value:"", label:"Please Select..."},
        {value: "AL", label:"Alabama"},
        {value: "AK", label: "Alaska"},
        {value: "AZ", label: "Arizona"},
        {value: "AR", label: "Arkansas"},
        {value: "CA", label: "California"},
        {value: "CO", label: "Colorado"},
        {value: "CT", label: "Connecticut"},
        {value: "DE", label: "Delaware"},
        {value: "DC", label: "District Of Columbia"},
        {value: "FL", label: "Florida"},
        {value: "GA", label: "Georgia"},
        {value: "HI", label: "Hawaii"},
        {value: "ID", label: "Idaho"},
        {value: "IL", label: "Illinois"},
        {value: "IN", label: "Indiana"},
        {value: "IA", label: "Iowa"},
        {value: "KS", label: "Kansas"},
        {value: "KY", label: "Kentucky"},
        {value: "LA", label: "Louisiana"},
        {value: "ME", label: "Maine"},
        {value: "MD", label: "Maryland"},
        {value: "MA", label: "Massachusetts"},
        {value: "MI", label: "Michigan"},
        {value: "MN", label: "Minnesota"},
        {value: "MS", label: "Mississippi"},
        {value: "MO", label: "Missouri"},
        {value: "MT", label: "Montana"},
        {value: "NE", label: "Nebraska"},
        {value: "NV", label: "Nevada"},
        {value: "NH", label: "New Hampshire"},
        {value: "NJ", label: "New Jersey"},
        {value: "NM", label: "New Mexico"},
        {value: "NY", label: "New York"},
        {value: "NC", label: "North Carolina"},
        {value: "ND", label: "North Dakota"},
        {value: "OH", label: "Ohio"},
        {value: "OK", label: "Oklahoma"},
        {value: "OR", label: "Oregon"},
        {value: "PA", label: "Pennsylvania"},
        {value: "RI", label: "Rhode Island"},
        {value: "SC", label: "South Carolina"},
        {value: "SD", label: "South Dakota"},
        {value: "TN", label: "Tennessee"},
        {value: "TX", label: "Texas"},
        {value: "UT", label: "Utah"},
        {value: "VT", label: "Vermont"},
        {value: "VA", label: "Virginia"},
        {value: "WA", label: "Washington"},
        {value: "WV", label: "West Virginia"},
        {value: "WI", label: "Wisconsin"},
        {value: "WY", label: "Wyoming"}
    ];
    
    const isNumeric = (str) => {
      if (typeof str != "string") return false
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
             !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    };
    
    
    /***************************
    /*      HANDLERS
    /***************************/
    const payButtonPressedHandler = (event) => {
        //REQUEST encrypted output          
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const randomValue = Math.floor(Math.random() * 100) + 1;
        
        const input = new URLSearchParams({
            VendorTxCode: currentTimestamp + randomValue,
            Amount: amount,
            Currency: "GBP",
            Description: "HKJ - Ref:"+referrerID+" - Name:"+deliveryFirstnames+" "+deliverySurname,
            ReferrerID: referrerID,
            CustomerName: deliveryFirstnames+" "+deliverySurname,
            CustomerEMail: customerEMail,
            BillingSurname: billingSurname,
            BillingFirstnames: billingFirstnames,
            BillingAddress1: billingAddress1,
            BillingCity: billingCity,
            BillingPostCode: billingPostCode,
            BillingCountry: billingCountry,
            BillingState: billingState,
            BillingPhone: billingPhone,
            DeliveryFirstnames: deliveryFirstnames,
            DeliverySurname: deliverySurname,
            DeliveryAddress1: deliveryAddress1,
            DeliveryCity: deliveryCity,
            DeliveryPostCode: deliveryPostCode,
            DeliveryCountry: deliveryCountry,
            DeliveryState: deliveryState,
            DeliveryPhone: deliveryPhone,
            SuccessURL: "https://payments.havenkjspecialist.com/success",
            FailureURL: "https://payments.havenkjspecialist.com/fail",
            VendorEMail: "maidstone@havenkjspecialist.com",          
        });
        
        let params = input.toString();
        let xmlhttp = new XMLHttpRequest();
        xmlhttp.open("POST", sageEncryptRequestURL, true);
        
        xmlhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xmlhttp.onreadystatechange = function() {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) { // XMLHttpRequest.DONE == 4
                if (xmlhttp.status === 200) {
                    //console.log("@"+xmlhttp.responseText);
                    setEncryptedOutput("@"+xmlhttp.responseText);
                }
                else if (xmlhttp.status === 400) {
                    alert('There was an error 400');
                }
                else {
                    alert('Something else other than 200 was returned');
                }
            }
        };
        xmlhttp.send(params); 
    };
       
    const formPageConfirmPrevClickHandler = (event) => {
        setCurrentPageId("details");
    };
    
    const formPageDetailsNextClickHandler = (event) => {
        let formPageValid = true;
        
        //set that the Form Page request to be validated has been sent
        setCurrentPageRequestedValidation(true);
        
        /****** FORM DETAILS VALIDATION *****/
        if (currentPageId === "details"){
            //Policy Holder Details
            if (deliveryFirstnames==="" || deliverySurname==="" || customerEMail==="" || deliveryPhone==="" || deliveryAddress1==="" || deliveryCity==="" || deliveryPostCode==="" || deliveryCountry===""){
                formPageValid = false;
            }
            
            //Billing Details
            if (!sameBillingAddress){
                if (billingFirstnames==="" || billingSurname==="" || billingPhone==="" || billingAddress1==="" || billingCity==="" || billingPostCode==="" || billingCountry===""){
                    formPageValid = false;
                }
            }
            else{
                //copy policy to billing
                setBillingFirstnames(deliveryFirstnames);
                setBillingSurname(deliverySurname);
                setBillingPhone(deliveryPhone);
                setBillingAddress1(deliveryAddress1);
                setBillingCity(deliveryCity);
                setBillingPostCode(deliveryPostCode);
                setBillingCountry(deliveryCountry);
            }
            
            //Payment Details
            if (referrerID==="" || amount===""){
                formPageValid = false;
            }
            
            //check particular fields
            //** emails
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(customerEMail)) formPageValid = false;
            
            //** amounts
            if (!isNumeric(amount)) formPageValid = false;
            
            if (deliveryCountry==="US" && deliveryState==="") formPageValid = false;
            if (billingCountry==="US" && billingState==="") formPageValid = false;
        }
        
        //formPageValid = true;
        if (formPageValid){
            //go to CONFIRM page
            setCurrentPageId("confirm");
            
            //scroll page to top
            window.scrollTo(0, 0);
        }
        
        //console.log("...FORM PAGE ID:"+currentPageId+" ... VALID:"+formPageValid);
    };
    
    const submitSagePayForm = (event) => {
        //Actually Submit the form
        let formSagePay = document.getElementById("form-sagepay");
        formSagePay.submit();
    };
    
    
    useEffect(() => {
        if (encryptedOutput !== ""){
            //Encrypted output is ready => submit the form
            submitSagePayForm();
        }
    }, [encryptedOutput]);
    
    
    return(
        <div className="form-wrapper">
            <ContentWrapper>
                <FormNavigation
                    pages={[
                        {id:"details", title:"Payment Details", completed:true},
                        {id:"confirm", title:"Confirm Payment", completed:(currentPageId!=="details") ? true : false},
                        {id:"complete", title:"Payment Complete", completed:false},
                    ]}
                />
                
                <form>
                    <FormPage
                        visible={currentPageId==="details"}
                        buttons={[
                            {type:"next", label:"Next", onClick:formPageDetailsNextClickHandler}
                        ]}
                        note="<b>Please note:</b> all fields are required."
                    >
                        <FormBlock>
                            <FormBlockDescription>
                                <Title size="3" tag="h2">Policy Holder Details</Title>
                                <p>This is your home address</p>
                            </FormBlockDescription>
                            
                            <FormBlockFields>
                                <FormField
                                    type="input"
                                    id="DeliveryFirstnames"
                                    value={deliveryFirstnames}
                                    onChange={(e) => setDeliveryFirstnames(e.currentTarget.value)}
                                    error="Please enter your policy first name"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                    help="Full name of Policy Holder is required for validation purposes"
                                >
                                    First Name(s) in Full
                                </FormField>
                                
                                <FormField
                                    type="input"
                                    id="DeliverySurname"
                                    value={deliverySurname}
                                    onChange={(e) => setDeliverySurname(e.currentTarget.value)}
                                    error="Please enter your policy surname"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Surname
                                </FormField>
                                
                                <FormField
                                    type="email"
                                    id="CustomerEMail"
                                    value={customerEMail}
                                    onChange={(e) => setCustomerEMail(e.currentTarget.value)}
                                    error="Please enter your email address"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Email
                                </FormField>
                                
                                <FormField
                                    type="input"
                                    id="DeliveryPhone"
                                    value={deliveryPhone}
                                    onChange={(e) => setDeliveryPhone(e.currentTarget.value)}
                                    error="Please enter your policy phone no."
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Phone no.
                                </FormField>
                                
                                <FormField
                                    type="input"
                                    id="DeliveryAddress1"
                                    value={deliveryAddress1}
                                    onChange={(e) => setDeliveryAddress1(e.currentTarget.value)}
                                    error="Please enter your policy first line of address"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Address Line 1
                                </FormField>
                                
                                <FormField
                                    type="input"
                                    id="DeliveryCity"
                                    value={deliveryCity}
                                    onChange={(e) => setDeliveryCity(e.currentTarget.value)}
                                    error="Please enter your policy city"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Town/City
                                </FormField>
                                
                                <FormField
                                    type="input"
                                    id="DeliveryPostCode"
                                    value={deliveryPostCode}
                                    onChange={(e) => setDeliveryPostCode(e.currentTarget.value)}
                                    error="Please enter your policy postcode"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Postcode
                                </FormField>
                                
                                <FormField
                                    type="select"
                                    id="DeliveryCountry"
                                    value={deliveryCountry}
                                    options={countriesList}
                                    onChange={(e) => setDeliveryCountry(e.currentTarget.value)}
                                    error="Please enter your policy country"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                >
                                    Country
                                </FormField>
                                
                                <FormFieldsGroup visible={deliveryCountry==="US"}>
                                    <FormField
                                        type="select"
                                        id="DeliveryState"
                                        value={deliveryState}
                                        options={USStatesList}
                                        onChange={(e) => setDeliveryState(e.currentTarget.value)}
                                        error="Please enter your policy state"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        State
                                    </FormField>
                                </FormFieldsGroup>
                                
                            </FormBlockFields>
                        </FormBlock>
                        
                        <FormBlock>
                            <FormBlockDescription>
                                <Title size="3" tag="h2">Billing Details</Title>
                            </FormBlockDescription>
                            
                            <FormBlockFields>
                                <FormField
                                    type="checkbox"
                                    id="SameBillingAddress"
                                    value={sameBillingAddress}
                                    onChange={(e) => { setSameBillingAddress((prevState) => {return !prevState;}) }}
                                >
                                    My billing details are the same as my policy details above.<br/>
                                    <em>Click here to enter different billing details.</em>
                                </FormField>
                            
                                <FormFieldsGroup visible={!sameBillingAddress}>
                                    <FormField
                                        type="input"
                                        id="BillingFirstnames"
                                        value={billingFirstnames}
                                        onChange={(e) => setBillingFirstnames(e.currentTarget.value)}
                                        error="Please enter your billing first name"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        First Name
                                    </FormField>
                                    
                                    <FormField
                                        type="input"
                                        id="BillingSurname"
                                        value={billingSurname}
                                        onChange={(e) => setBillingSurname(e.currentTarget.value)}
                                        error="Please enter your billing surname"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        Surname
                                    </FormField>
                                    
                                    <FormField
                                        type="input"
                                        id="BillingPhone"
                                        value={billingPhone}
                                        onChange={(e) => setBillingPhone(e.currentTarget.value)}
                                        error="Please enter your billing phone no."
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        Phone no.
                                    </FormField>
                                    
                                    <FormField
                                        type="input"
                                        id="BillingAddress1"
                                        value={billingAddress1}
                                        onChange={(e) => setBillingAddress1(e.currentTarget.value)}
                                        error="Please enter your billing first line of address"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        Address Line 1
                                    </FormField>
                                    
                                    <FormField
                                        type="input"
                                        id="BillingCity"
                                        value={billingCity}
                                        onChange={(e) => setBillingCity(e.currentTarget.value)}
                                        error="Please enter your billing city"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        Town/City
                                    </FormField>
                                    
                                    <FormField
                                        type="input"
                                        id="BillingPostCode"
                                        value={billingPostCode}
                                        onChange={(e) => setBillingPostCode(e.currentTarget.value)}
                                        error="Please enter your billing postcode"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        Postcode
                                    </FormField>
                                    
                                    <FormField
                                        type="select"
                                        id="BillingCountry"
                                        value={billingCountry}
                                        options={countriesList}
                                        onChange={(e) => setBillingCountry(e.currentTarget.value)}
                                        error="Please enter your billing country"
                                        currentPageRequestedValidation={currentPageRequestedValidation}
                                    >
                                        Country
                                    </FormField>
                                    
                                    <FormFieldsGroup visible={billingCountry==="US"}>
                                        <FormField
                                            type="select"
                                            id="BillingState"
                                            value={billingState}
                                            options={USStatesList}
                                            onChange={(e) => setBillingState(e.currentTarget.value)}
                                            error="Please enter your billing state"
                                            currentPageRequestedValidation={currentPageRequestedValidation}
                                        >
                                            State
                                        </FormField>
                                    </FormFieldsGroup>
                                </FormFieldsGroup>
                                
                            </FormBlockFields>
                        </FormBlock>
                        
                        <FormBlock>
                            <FormBlockDescription>
                                <Title size="3" tag="h2">Payment Details</Title>
                                <p className="smalltext">Haven Knox-Johnston Specialist is a trading name of Howden UK Brokers Limited. Please note when purchasing your policy with us your bank statement will show Howden UK Brokers Limited against your payment.</p>
                            </FormBlockDescription>
                            
                            <FormBlockFields>
                                <FormField
                                    type="input"
                                    id="ReferrerID"
                                    value={referrerID}
                                    onChange={(e) => setReferrerID(e.currentTarget.value)}
                                    error="Please enter your reference no."
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                    notes={"<p>Please see this <a href='"+exampleRef+"' target='_blank'>EXAMPLE</a> of where you can find your policy number and premium.</p>"}
                                >
                                    Policy Number
                                </FormField>
                                
                                <FormField
                                    type="amount"
                                    id="Amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.currentTarget.value)}
                                    error="Please enter an amount"
                                    currentPageRequestedValidation={currentPageRequestedValidation}
                                    notes={"<p>Please pay the 'Total Premium (£)' amount detailed on your Certificate.</p>"}
                                >
                                    Amount (£)
                                </FormField>
                                
                            </FormBlockFields>
                        </FormBlock>
                    </FormPage>
                    
                    <FormPage
                        visible={currentPageId==="confirm"}
                        buttons={[
                            {type:"submit", label:"Pay", onClick:payButtonPressedHandler, info:"<p>You will now be transferred to Howden UK Brokers Limited Payment facility. Haven Knox Johnston Specialist is a trading name of Howden UK Brokers Limited.</p><p>Please ensure the information you have entered is correct before submitting.</p><p>By clicking \"Pay\" you agree to our <a href='https://www.howdengroup.com/uk-en/terms-and-conditions' target='_blank'>terms & conditions</a>.</p>"},
                            
                            {type:"prev", label:"Edit", onClick:formPageConfirmPrevClickHandler, info:"<p>If you would like to change any of the information above click the edit button.</p>"}
                        ]}
                    >
                        <div className="columns-wrapper">
                            <div className="column">
                                <table className="confirm">
                                    <tbody>
                                        <tr>
                                            <th>Name</th>
                                            <td>{deliveryFirstnames} {deliverySurname}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{customerEMail}</td>
                                        </tr>
                                        <tr>
                                            <th>Policy name</th>
                                            <td>{deliveryFirstnames} {deliverySurname}</td>
                                        </tr>
                                        <tr>
                                            <th>Policy phone no.</th>
                                            <td>{deliveryPhone}</td>
                                        </tr>
                                        <tr>
                                            <th>Policy address</th>
                                            <td>{deliveryAddress1}, {deliveryCity}, {deliveryPostCode}, {deliveryCountry}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="column">
                                <table className="confirm">
                                    <tbody>
                                        <tr>
                                            <th>Contact Reference</th>
                                            <td>{referrerID}</td>
                                        </tr>
                                        <tr>
                                            <th>Amount</th>
                                            <td>{amount}</td>
                                        </tr>
                                        <tr>
                                            <th>Billing Name</th>
                                            <td>{billingFirstnames} {billingSurname}</td>
                                        </tr>
                                        <tr>
                                            <th>Billing phone no.</th>
                                            <td>{billingPhone}</td>
                                        </tr>
                                        <tr>
                                            <th>Billing Address</th>
                                            <td>{billingAddress1}, {billingCity}, {billingPostCode}, {billingCountry}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </FormPage>
                </form>
                
                <form id="form-sagepay" action="https://live.sagepay.com/gateway/service/vspform-register.vsp" method="post">
                    <input type="hidden" name="VPSProtocol" value="3.00"/>
                    <input type="hidden" name="TxType" value="PAYMENT"/>
                    <input type="hidden" name="Vendor" value="astonlark"/>
                    <input type="hidden" name="Crypt" value={encryptedOutput}/>
                </form>
            </ContentWrapper>
        </div>
    );
}

export default Form;