//CSS
import fontscss from "./assets/fonts/fonts.css";

//ASSETS

//COMPONENTS
import React, {useState, useEffect} from 'react';
import {createBrowserRouter, RouterProvider, Outlet} from 'react-router-dom';
import WebFont from 'webfontloader';

import Header from "./components/Header";
import Logo from "./components/Logo";
import Error from "./components/Error";
import Form from "./components/Form";
import Results from "./components/Results";
import Footer from "./components/Footer";

const router = createBrowserRouter([
    {
        path: '/',
        element: <Outlet/>,
        errorElement: <Error/>,
        children:[
            { path: '/', element: <Form/> },
            { path: '/success/', element: <Results success={true}/> },
            { path: '/fail/', element: <Results success={false}/> },
        ]
    }
]);

function App() {
    const [fontLoaded,setFontLoaded] = useState(false);
  
    useEffect(() => {
        WebFont.load({
            custom: {
                families: ['Mabry Pro'],
                urls: [fontscss]
            },
            active: () => {
                setFontLoaded(true);
            },
            timeout: 2000
        });
    }, []);

    return (
        <React.Fragment> 
        {fontLoaded &&
            <React.Fragment> 
                <Header/>
                <Logo/>
                <RouterProvider router={router}/>
                <Footer/>
            </React.Fragment>
        }
        </React.Fragment>
    );
}

export default App;
