//CSS
import "./css/Footer.css";

//ASSETS

//COMPONENTS
import ContentWrapper from "./layoutcomponents/ContentWrapper";


const Footer = (props) => {

    return(
        <div className="footer">
            <ContentWrapper>
                <p className="smalltext">© 2023 Howden UK Brokers Limited. All Rights Reserved - <a href="https://www.howdengroup.com/uk-en/privacy-data-protection-policy" target="_blank">Privacy Notice</a> - <a href="https://www.howdengroup.com/uk-en/terms-and-conditions" target="_blank">Terms & Conditions</a></p>
                
                <p className="smalltext">Haven Knox-Johnston Specialist is a trading name of Howden UK Brokers Limited Registered in England & Wales under company registration number 725875. Registered address: One Creechurch Place, London, EC3A 5AF, United Kingdom. Authorised and regulated by the Financial Conduct Authority.</p>
            </ContentWrapper>
        </div>
);
}

export default Footer;