//CSS
import "./css/Error.css";

//ASSETS

//COMPONENTS
import React from 'react';
import {Link} from 'react-router-dom';

import ContentWrapper from "./layoutcomponents/ContentWrapper";
import Title from "./layoutcomponents/Title";

const Error = (props) => {

    return(
        <div className="box-error">
            <ContentWrapper>
                <Title size="1" tag="h1">404 - Page Not Found</Title>
                <Link className="link" to="/">Start Over</Link>
            </ContentWrapper>
        </div>
    );
}

export default Error;