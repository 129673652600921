//CSS
import "./css/Header.css";

//ASSETS

//COMPONENTS
import ContentWrapper from "./layoutcomponents/ContentWrapper";


const Header = (props) => {

    return(
        <div className="header">
            <ContentWrapper>
              <a className="phone" href="tel:00441843603345">CALL: +44 (0)1843 603345</a>
            </ContentWrapper>
        </div>
    );
}

export default Header;