//CSS

//ASSETS

//COMPONENTS
import React from 'react';


const FormFieldsGroup = (props) => {
    if (!props.visible){
        return(<React.Fragment></React.Fragment>);
    }
    return(
        <div className="form-fields-group">
            {props.children}
        </div>
    );
    
}

export default FormFieldsGroup;