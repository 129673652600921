//CSS
import "./css/Title.css";

//ASSETS

//COMPONENTS
import React from 'react';

const Title = (props) => {
    let size="1";
    if (typeof props.size !== 'undefined'){
      size = props.size;
    }
    
    let tag = "h1";
    if (typeof props.tag !== 'undefined'){
      tag = props.tag;
    }
    
    return(
      React.createElement(tag, {className:"title-size"+size}, props.children)
    );
}

export default Title;